.textdivider {
  margin: 0 auto;
  max-width: 420px;
  text-align: center;
  width: 100%;
}
.textdivider h3 {
  text-align: center;
  display: block;
  overflow: hidden;
  margin: 15px 20px 0;
}
.textdivider h3 span {
  height: 30px;
  position: relative;
  display: inline-block;
  font-size: 1rem;
  color:rgba(0,0,0,.4);
}
.textdivider h3 span::before, .textdivider h3 span::after {
  content: "";
  position: absolute;
  top: 35%;
  height: 1px;
  background: #757575;
  width: 99999px;
}
.textdivider h3 span::before {
  left: 100%;
  margin-left: 60px;
}
.textdivider h3 span::after {
  right: 100%;
  margin-right: 60px;
}


.or-spacer {
  margin-top: 100px;
  width: 400px;
  position: relative;
}
.or-spacer .mask {
  overflow: hidden;
  height: 20px;
}
.or-spacer .mask:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px / 12px;
  box-shadow: 0 0 8px black;
}
.or-spacer span {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -25px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: white;
}
.or-spacer span i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
  border: 1px dashed #aaa;
  text-align: center;
  line-height: 40px;
  font-style: normal;
  color: #999;
}

.or-spacer-vertical {
  display: inline-block;
  margin-top: 100px;
  margin-left: 100px;
  width: 100px;
  position: absolute;
}
.or-spacer-vertical .mask {
  overflow: hidden;
  width: 20px;
  height: 200px;
}
.or-spacer-vertical.left .mask:after {
  content: '';
  display: block;
  margin-left: -20px;
  width: 20px;
  height: 100%;
  border-radius: 12px / 125px;
  box-shadow: 0 0 8px black;
}
.or-spacer-vertical.right .mask:before {
  content: '';
  display: block;
  margin-left: 20px;
  width: 20px;
  height: 100%;
  border-radius: 12px / 125px;
  box-shadow: 0 0 8px black;
}
